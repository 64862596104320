import { Box, Flex, Grid, Heading, LinkBox, Stack, Text } from "@biblioteksentralen/react";
import { ResolvedRecommendationSummary } from "@libry-content/common";
import { useId } from "react";
import { getPath } from "../../utils/getPath";
import { usePublicationLangAttribute } from "../../utils/hooks/usePublicationLangAttribute";
import { useTranslation } from "../../utils/hooks/useTranslation";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { Edit } from "../editInSanity/EditInSanity";
import CreatedByTag from "../employees/CreatedByTag";
import CoverImageWithPlaceholder from "../lists/CoverImageWithPlaceholder";
import { MediaTypesAndTargetAudiences } from "../lists/MediaTypesAndTargetAudiences";
import { Teaser } from "./Teaser";
import { getRecommendationPalette } from "./utils";

interface Props {
  recommendation: ResolvedRecommendationSummary;
  headingSize?: "h2" | "h3";
}

const RecommendationCard = ({ recommendation, headingSize = "h3" }: Props) => {
  const { ts } = useTranslation();
  const publicationLangAttribute = usePublicationLangAttribute(recommendation.publication);
  const headingId = useId();
  const palette = getRecommendationPalette(recommendation);

  if (!recommendation.publication) return null;

  const { publication, targetAudiences } = recommendation;
  const [title] = recommendation.publication.title?.split(":") ?? [];
  const teaserLenght = ts(recommendation.teaser)?.length ?? 0;

  return (
    <Stack isolation="isolate" aria-labelledby={headingId}>
      <LinkBox>
        <Grid
          gridTemplateColumns={{ base: ".6fr 1fr" }}
          gridTemplateRows={{ base: "4rem 9rem 1fr", lg: "5rem 9rem 1fr" }}
          role="group"
        >
          <Grid
            gridColumn="1/2"
            gridRow={publication?.mediaTypes?.includes("CD") ? "2/3" : "1/3"}
            justifyContent="center"
            alignItems="center"
            paddingLeft="1rem"
          >
            <CoverImageWithPlaceholder publication={publication} imageWidth="7rem" minWidth="3rem" />
          </Grid>
          <Teaser
            gridColumn="2/3"
            gridRow="2/3"
            alignSelf="center"
            padding=".5rem .75rem .5rem .25rem"
            fontSize={teaserLenght > 50 ? ".7rem" : "sm"}
            noOfLines={6}
            recommendation={recommendation}
            quoteMarkProps={{
              ...palette.quoteMarkProps,
              marginRight: "-.25em",
            }}
            {...palette.teaserTextProps}
          />
          <Flex gridColumn="1/3" gridRow="3/4" flexDir="column" gap="0.2rem" paddingTop=".5rem">
            <MediaTypesAndTargetAudiences mediaTypes={publication.mediaTypes} targetAudiences={targetAudiences} />
            <InternalLinkOverlay href={getPath(recommendation)} _groupHover={{ textDecoration: "underline" }}>
              <Heading as={headingSize} size="md" noOfLines={2} lang={publicationLangAttribute} id={headingId}>
                {title}
              </Heading>
            </InternalLinkOverlay>
            <Text fontSize="sm" lang={publicationLangAttribute}>
              {recommendation.publication.author}
            </Text>
          </Flex>
          <Box
            gridColumn="1/3"
            gridRow="2/3"
            background={palette.mainPalette?.backgroundColor}
            borderRadius=".5rem"
            zIndex="-1"
          />
        </Grid>
      </LinkBox>
      <CreatedByTag createdBy={recommendation.createdBy} paletteColor={palette.createdByPalette} />
      <Edit doc={recommendation} />
    </Stack>
  );
};

export default RecommendationCard;
